// TODO figure out how to share this types across verious apps

export enum NonAudienceVoteType {
  jury = "jury",
  rappr = "rappr",
}

export enum AudienceVoteType {
  aud = "aud",
}

export enum ESessionOpenTarget {
  openAud = "openAud",
  openNonAud = "openNonAud",
}
export interface IJudge {
  name: string;
  multiplier: number;
}

export enum EScheduleLimits {
  start = "start",
  end = "end",
}
export interface ISchedule<T = number> {
  [EScheduleLimits.start]: T;
  [EScheduleLimits.end]: T;
}

export enum ESessionActivationType {
  onPrompt = "onPrompt",
  onSchedule = "onSchedule",
}
export interface IOnPromptOpenInfo {
  type: ESessionActivationType.onPrompt;
  open: boolean;
}
export interface IOnScheduleOpenInfo {
  type: ESessionActivationType.onSchedule;
  open: ISchedule;
}

export type TOpenInfo = IOnPromptOpenInfo | IOnScheduleOpenInfo;

export type TOpenTargetActivateInfo = {
  [key in ESessionOpenTarget]: TOpenInfo;
};

export enum VotingScheme {
  basic = "basic",
  selectSongs = "selectSongs",
}

export type FavoriteTalentIds = { [groupName: string]: string[] };

interface ISessionBasic extends TOpenTargetActivateInfo {
  id: string;
  description: string;
  // TODO manage the fact of not having judges and so not include TOpenTargetActivateInfo for judges
  judges: IJudge[];
  groups: IGroup[];
  includeRappr: boolean;
  giftTicketsOpen: boolean;
  ticketsOpen: boolean;
  votingScheme: VotingScheme;
  favoriteTalentIds?: FavoriteTalentIds;
}

interface ISessionBasicScheme extends ISessionBasic {
  votingScheme: VotingScheme.basic;
  songsPerTalent?: never;
}
interface ISessionSongScheme extends ISessionBasic {
  votingScheme: VotingScheme.selectSongs;
  songsPerTalent: number;
}

export type ISession = ISessionBasicScheme | ISessionSongScheme;

export interface IPublicInfo {
  streamingUrl: string;
  showStreamingBtn: boolean;
  igUrl: string;
}

export interface IJudgeDoc {
  judges: string[];
}

export type VoteType = AudienceVoteType | NonAudienceVoteType;

export type ILiveRankInit = { [talId: string]: number };

export enum ERequestStatus {
  pending = "pending",
  accepted = "accepted",
  rejected = "rejected",
}

export interface IGiftTicketRequest {
  name?: string;
  igHandle?: string;
  mobile?: string;
  ticketsCount?: string;
  email?: string;
  type: TGiftType;
  status: ERequestStatus;
}
export interface ILiveRankUpdate {
  target: string;
  voteDiff: number;
  type: VoteType;
}
export interface ILiveRankDoc {
  initVotes: ILiveRankInit;
  updates: ILiveRankUpdate[];
  text: string;
  groups: IGroup[];
}
export interface IComputeLiveRankData {
  targetSessionId: string;
}

export interface NonAudVoteDoc {
  [talId: string]: number;
}

export type IVoteMeta = {
  schoolEmail?: string;
  classroom?: string;
  lastname?: string;
};

export type IVoteInfo = {
  timestamp: number;
  metadata: IVoteMeta;
};

export type IVoteData = {
  [groupName: string]: { talId: string; songId?: string } | null;
};
export type TVotePerGroup = { [index: string]: { [index: string]: number } };
export type TNonAudPswDoc = {
  [username: string]: { psw: string; type: NonAudienceVoteType };
};

export type TJuryVotes = { [talId: string]: number };
export type TJuryVotesDoc = { [username: string]: TJuryVotes };
export type TRapprVote = string;
export type TRapprVoteDoc = { [username: string]: TRapprVote };

export type TNonAudVotesDoc = { [username: string]: TJuryVotes | TRapprVote };

export interface IVoteTalentFromNonAudData {
  votes: {
    [NonAudienceVoteType.jury]?: TJuryVotes;
    [NonAudienceVoteType.rappr]?: TRapprVote;
  };
  username: string;
  password: string;
}
export interface IVoteTalentFuncData {
  votes: IVoteData;
  targetSessionId: string;
  metaData?: IVoteMeta;
}

export interface IGroup {
  name: string;
  talents: ITalent[];
}

export interface ISong {
  id: string;
  name: string;
  author: string;
  urls: string[];
  isOriginal: boolean;
}

export interface ITalentBasic {
  name: string;
  schoolName: string;
  schoolType: string;
  igHandlers: string[];
  imgUrl?: string;
  songs?: ISong[];
  // TODO think if it makes sense to create a ISchool
  schoolDomain?: string;
}

// TODO make interface for vote shard
export interface ITalentRank extends ITalentBasic {
  totalVotes: number;
  songsVotes?: { [songId: string]: number };
}

export interface ITalent extends ITalentBasic {
  videoUrl: string;
  id: string;
}

export interface IVotePerGroup {
  [groupName: string]: { [talId: string]: ITalentRank };
}

export interface IRankData {
  lastUpdate: number;
  votesPerGroup: IVotePerGroup;
  totalVotes: number;
}

export interface IGetLiveRankData {
  username: string;
  psw: string;
  targetSessionId: string;
}
export interface ILiveRankCred {
  username: string;
  psw: string;
}

export interface ICreateSessionData {
  session: ISession;
  liveRankCred: ILiveRankCred;
}

export enum TGiftTypes {
  press = "press",
  giveAway = "giveAway",
  institutions = "institutions",
  partners = "partners",
  gvFriends = "gvFriends",
  principals = "principals",
}
export type TGiftType = keyof typeof TGiftTypes;
export const GiftTypesHumanReadable: { [type in TGiftType]: string } = {
  press: "Stampa",
  giveAway: "Give Away",
  institutions: "Istituzioni",
  partners: "Partners",
  gvFriends: "GV friends",
  principals: "Dirigenza scolastisca",
};
export interface IFbMailServiceMessage {
  subject: string;
  html: string;
}
export interface IFbMailService {
  to: string[];
  message: IFbMailServiceMessage;
}

export interface IGetAudRankData {
  targetSessionId: string;
}
export interface IAudRankData {
  audRank: { [talId: string]: ITalentRank };
  totalVotes: number;
}

export interface IDeleteSessionData {
  targetSessionId: string;
}

export interface ITicketRequest {
  firstName: string;
  lastName: string;
  phone: string;
  status: ERequestStatus;
}

export interface IEditSessionData {
  addTalents?: {
    talentsToAdd: ITalent[];
    targetGroupName: string;
  };
  targetSessionId: string;
}

export type FantaEvent = {
  description: string;
  points: number;
  professor: string;
  date: Date;
  processed: boolean;
};
export interface UserFantaInfo {
  professors: string[];
  professorPoints: { [profName: string]: number };
  totalPoints: number;
  rankPosition: number | undefined;
  mainProfessor: string;
  name: string;
  schoolId: string;
  subscribedAt: Date;
}
