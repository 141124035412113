import CustomSelect from "../components/misc/CustomSelect";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import CustomInput from "./misc/CustomTextField";
import CustomButton from "../components/misc/CustomButton";
import { db } from "../fbConfig";
import CustomDialog from "../components/misc/CustomDialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import { TGiftType, TGiftTypes, GiftTypesHumanReadable } from "../interfaces";
import { GIFT_TICKETS_COLL_ID, SESSIONS_COLL_ID } from "../constants";
import { doc, setDoc } from "firebase/firestore";
import useSessionActive from "../hooks/useSessionActive";

type TGuestType = "Azienda o associazione" | "Privato";
const GUEST_TYPES: TGuestType[] = ["Azienda o associazione", "Privato"];
const PARTNERS: string[] = [
  "CNA",
  "Kaplan",
  "Talent Garden",
  "VisionBroad",
  "OrientaMenti",
  "MayVoice",
  "2M Music Agency",
  "Kroma Agency",
  "OstelloBello",
  "Naima Academy",
  "Techno-Logic",
];
const TYPES = Object.values(TGiftTypes);

const isSubmitDisabled = (
  type: TGiftType | "",
  {
    name,
    mobile,
    igHandle,
    ticketsCount,
    email,
  }: {
    name: string;
    mobile: string;
    igHandle: string;
    ticketsCount: string;
    email: string;
  }
) => {
  console.log({ type, name, mobile, igHandle, ticketsCount, email });
  if (email === "") return true;
  if (name === "") return true;
  if (type !== "press" && ticketsCount === "") return true;
  switch (type) {
    case "":
      return true;
    case "giveAway":
      if ([igHandle, mobile].some((field) => field === "")) return true;
      break;
  }
  return false;
};

function GiftTicketsReserver() {
  const [selectedType, setSelectedType] = useState<TGiftType | "">("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [igHandle, setIgHandle] = useState("");
  const [name, setName] = useState("");
  const [ticketsCount, setTicketsCount] = useState("");
  const [isWaitingForVote, setIsWaitingForVote] = useState(false);
  const [errorMsg, setErrorMsg] = useState<undefined | string>("ciao");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [guestType, setGuestType] = useState<TGuestType | "">("");

  const sessionId = useSessionActive("giftTicketsOpen");

  const lastSelectedType = useRef("");

  useEffect(() => {
    if (selectedType !== lastSelectedType.current) setName("");
    lastSelectedType.current = selectedType;
  }, [selectedType]);

  const requestGift = (targetSessionId: string) => {
    setIsDialogOpen(true);
    setIsWaitingForVote(true);

    const reqDocRef = doc(
      db,
      SESSIONS_COLL_ID,
      targetSessionId,
      GIFT_TICKETS_COLL_ID,
      email
    );

    const reqDoc = {
      name,
      mobile,
      igHandle,
      ticketsCount,
      type: selectedType as TGiftType,
      status: "pending",
    };

    setDoc(reqDocRef, reqDoc, { merge: true })
      .then(() => {
        setIsWaitingForVote(false);
        setErrorMsg(undefined);
        console.info("Request creation and email sending successful");
      })
      .catch((err) => {
        setIsWaitingForVote(false);
        setErrorMsg(
          "C'è stato un errore, riprova più tardi. Se il problema persiste contatta it@gvnetwork.it"
        );
        console.error(
          "ERROR WHEN CREATING A REQUEST OR SENDING THE EMAIL",
          err
        );
      });
  };

  return (
    <div
      style={{
        width: "85%",
        display: "flex",
        flexDirection: "column",
        margin: "30px auto",
        alignItems: "center",
        maxWidth: 500,
      }}
    >
      <h3 style={{ fontSize: "1.2rem" }}>XX22</h3>
      <h1 style={{ fontSize: "2.5rem" }}>GENVISION</h1>
      <h3 style={{ textAlign: "center", fontSize: "1.5rem", margin: "20px 0" }}>
        Scegli per quale categoria vuoi accreditarti
      </h3>

      {sessionId === undefined ? (
        <>
          <CircularProgress />
        </>
      ) : sessionId === null ? (
        <h3
          style={{ textAlign: "center", fontSize: "1.5rem", margin: "20px 0" }}
        >
          La prenotazione di accrediti è chiusa
        </h3>
      ) : (
        <>
          <CustomSelect
            value={selectedType}
            setValue={setSelectedType}
            options={TYPES.map((type) => ({
              label: GiftTypesHumanReadable[type],
              value: type,
            }))}
            label=""
          />
          {selectedType !== "" && (
            <CustomInput
              value={email ?? ""}
              setValue={setEmail as Dispatch<SetStateAction<string>>}
              disabled={false}
              type="text"
              label="Email"
              style={{ width: "100%" }}
            />
          )}
          {selectedType === "press" && (
            <CustomInput
              value={name}
              setValue={setName}
              type="text"
              disabled={false}
              label="Testata"
              style={{ width: "100%" }}
            />
          )}
          {selectedType === "principals" && (
            <CustomInput
              value={name}
              setValue={setName}
              type="text"
              disabled={false}
              label="Scuola"
              style={{ width: "100%" }}
            />
          )}
          {["giveAway", "institutions"].includes(selectedType) && (
            <>
              <CustomInput
                value={name}
                setValue={setName}
                type="text"
                disabled={false}
                style={{ width: "100%" }}
                label="Nome completo"
              />
            </>
          )}
          {selectedType === "giveAway" && (
            <>
              <CustomInput
                style={{ width: "100%" }}
                value={igHandle}
                setValue={setIgHandle}
                type="text"
                disabled={false}
                label="Acount instagram"
              />
              <CustomInput
                style={{ width: "100%" }}
                value={mobile}
                setValue={setMobile}
                type="text"
                disabled={false}
                label="Cellulare"
              />
            </>
          )}
          {selectedType === "partners" && (
            <>
              <h4
                style={{
                  color: "#FFF",
                  margin: "20px auto",
                  fontSize: "1.1rem",
                }}
              >
                Seleziona la realtà partner
              </h4>
              <CustomSelect
                value={name}
                setValue={setName}
                options={PARTNERS.map((type) => ({
                  label: type,
                  value: type,
                }))}
                label=""
              />
            </>
          )}
          {selectedType === "gvFriends" && (
            <>
              <h4
                style={{
                  color: "#FFF",
                  margin: "20px auto",
                  fontSize: "1.1rem",
                  textAlign: "center",
                }}
              >
                Indica se richiesti da azienda, associazione o privato
              </h4>
              <CustomSelect
                value={guestType}
                setValue={setGuestType}
                options={GUEST_TYPES.map((type) => ({
                  label: type,
                  value: type,
                }))}
                label=""
              />
              {guestType !== "" && (
                <>
                  <CustomInput
                    style={{ width: "100%" }}
                    value={name}
                    setValue={setName}
                    type="text"
                    disabled={false}
                    label={
                      guestType === "Azienda o associazione"
                        ? "Nome azienda o associazione"
                        : "Nome"
                    }
                  />
                </>
              )}
            </>
          )}
          {selectedType !== "" && selectedType !== "press" && (
            <>
              <h4
                style={{
                  textAlign: "center",
                  fontSize: "1.2rem",
                  margin: "20px 0",
                  color: "#FFF",
                }}
              >
                Indica il numero di biglietti richiesti
              </h4>
              <CustomSelect
                value={ticketsCount ?? ""}
                setValue={setTicketsCount}
                options={Array.from(Array(10).keys()).map((elm) => ({
                  label: (elm + 1).toString(),
                  value: (elm + 1).toString(),
                }))}
                label="Totale biglietti"
              />
            </>
          )}
          <CustomButton
            onClick={() => {
              requestGift(sessionId);
            }}
            disabled={isSubmitDisabled(selectedType, {
              name,
              mobile,
              igHandle,
              ticketsCount,
              email,
            })}
            confirmMsg={`Confermi di voler richiedere un accredito con la mail ${email}`}
            text="RICHIEDI ACCREDITO"
            style={{ margin: 20 }}
          />
          <h3
            style={{
              textAlign: "center",
              fontSize: "1.2rem",
              margin: "20px 0",
            }}
          >
            Una volta richiesto l'accredito, controlleremo i tuoi dati e ti
            invieremo la conferma di accettazione
          </h3>
          <CustomDialog
            open={isDialogOpen}
            onClose={() => {
              setIsDialogOpen(false);
            }}
          >
            <div style={{ margin: 30, color: "#FFF" }}>
              {isWaitingForVote ? (
                <CircularProgress />
              ) : errorMsg === undefined ? (
                <p style={{ fontSize: "1.2rem", textAlign: "center" }}>
                  Abbiamo ricevuto la sua richiesta. Le invieremo una mail con
                  la conferma di accettazione.
                </p>
              ) : (
                <p
                  style={{
                    color: "red",
                    fontSize: "1.5rem",
                    textAlign: "center",
                  }}
                >
                  {errorMsg}
                </p>
              )}
            </div>
          </CustomDialog>
        </>
      )}
    </div>
  );
}

export default GiftTicketsReserver;
